// import '../css/style.scss'
import { ua } from './modules/ua'
// import inView from 'in-view'
// import Swiper from 'swiper/bundle'

const klass = {
  active: 'is-active',
  fixed: 'is-fixed',
  view: 'is-view',
  selected: 'is-selected',
  hidden: 'is-hidden',
  eventNone: 'is-eventNone',
  landscape: 'is-landscape',
  current: 'is-current',
  large: 'is-large',
  nav: 'view-nav',
}

class App {
  constructor() {
    this.body = document.querySelector('body')
    this.init()
  }

  init() {
    this.initPages()
  }

  initPages() {
    // 全ページ共通適用
    this.body.classList.add(`is-${ua.browser()}`)
    this.body.classList.add(`is-${ua.os()}`)

    window.addEventListener('load', () => {
      setTimeout(() => {
        this.body.classList.add(`is-loaded`)
      }, 1000)
    })

    // inView('.inview').on('enter', (el) => {
    //   el.classList.add('is-view')
    // })
    // inView.offset(100)
  }
}

document.addEventListener('DOMContentLoaded', () => {
  window.sc22 = new App()
})
